import { TMessageMethods } from '../../store/messageMethods/types'
import { GetCategoryCsv, GetTokensForViewLikeAUser, GetUsersCsvTemplate } from '../../store/admin/users/actions'
import { downloadFile } from '../FileUtils'
import { getParsedAccessToken } from '../checkToken'
import { ROUTES } from '../routes'
import { SetCurrentUsersId } from '../../store/admin/settings/cms/actions'
import { checkLength, checkLowerCase, checkNumbers, checkSymbols, checkUpperCase } from '../helpers'
import { SECOND_DOMAIN_URL, VIEW_MOD_INFO } from '../consts'
import { TViewModeInfo } from '../../types/viewLikeTypes'

/// ////////////////////////////////////////////////////////////////////////////////////////////
export const emailIsValid = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email.toLowerCase())
}

export const passwordIsValidOld = (password: string): boolean =>
  !!(password && password.length > 6 && password.search(' ') === -1)

export const passwordIsValid = (password: string): boolean => {
  const minRulesCount = 3
  const upperCase = checkUpperCase(password) ? 1 : 0
  const lowerCase = checkLowerCase(password) ? 1 : 0
  const numbers = checkNumbers(password) ? 1 : 0
  const symbols = checkSymbols(password) ? 1 : 0
  return checkLength(password) && upperCase + lowerCase + numbers + symbols >= minRulesCount
}

export const inputIsValid = (value: string): boolean => /[A-Za-z\-_]$/.test(value)
export const phoneIsValid = (value: string): boolean => value.length >= 10 && value.length <= 15
/// ////////////////////////////////////////////////////////////////////////////////////////////

export const onEmailChange = (
  event: any,
  setValue: (value: string) => void,
  setValueError: (value: boolean) => void
) => {
  setValue(event.target.value)
  setValueError(!emailIsValid(event.target.value))
}

export const onPasswordChange = (
  event: any,
  setValue: (value: string) => void,
  setValueError: (value: boolean) => void
) => {
  const newPass = event.target.value.replaceAll(' ', '')
  setValue(newPass)
  setValueError(!passwordIsValid(newPass))
}

export const onPasswordChangeOld = (
  event: any,
  setValue: (value: string) => void,
  setValueError: (value: boolean) => void
) => {
  const newPass = event.target.value.replaceAll(' ', '')
  setValue(newPass)
  setValueError(!passwordIsValidOld(newPass))
}

export const onPasswordRepeatChange = (
  event: any,
  firstValue: string,
  setValue: (value: string) => void,
  setValueError: (value: boolean) => void
) => {
  const newPass = event.target.value.replaceAll(' ', '')
  setValue(newPass)
  setValueError(firstValue !== newPass || !passwordIsValid(newPass))
}

export const onInputChange = (
  event: any,
  setValue: (value: string) => void,
  setValueError: (value: boolean) => void
) => {
  setValue(event.target.value)
  setValueError(!inputIsValid(event.target.value))
}

export const onEmptyInputChange = (
  event: any,
  setValue: (value: string) => void,
  setValueError: (value: boolean) => void
) => {
  setValue(event.target.value)
  setValueError(event.target.value.trim() === '')
}

export const onNumberChange = (
  event: any,
  value: string,
  setValue: (value: string) => void,
  setValueError: (value: boolean) => void
) => {
  setValue(event)
  setValueError(!phoneIsValid(event))
}
/// ////////////////////////////////////////////////////////////////////////////////////////////

export const handleSelect = (e: any, setValue: any, setValueError: (value: boolean) => void) => {
  setValue(e)
  setValueError(!e)
}

export const handleSelectBoolean = (e: any, setValue: (value: boolean) => void) => setValue(e.value === 'true')

export const handleMultiSelect = (e: any, setValue: any, setError: any) => {
  const result = Array.isArray(e) ? e.map((x) => x) : []
  setValue(result)
  setError(!result.length)
}

export const getValueForSelect = (stateValue: {} | undefined | string): { value: string; label: string } => {
  if (typeof stateValue === 'string') return { value: `${stateValue}`, label: `${stateValue}` }

  const obj = stateValue ? Object.entries(stateValue) : null

  if (obj && obj[0][1] && obj[1][1]) {
    return { value: `${obj[0][1]}`, label: `${obj[1][1]}` }
  }
  return { value: '', label: '' }
}

export const getDefaultValue = (
  arr: TMessageMethods[] | null,
  message_method_id: number | undefined
): { value: string; label: string } =>
  getValueForSelect(arr?.find((item) => item.message_method_id === message_method_id))

const setViewLikeInfo = (idUser: number, access_token: string, recommendMode: boolean) => {
  const oldModeInfo = localStorage.getItem(VIEW_MOD_INFO)
  const parsedOldModeInfo: TViewModeInfo[] = oldModeInfo ? JSON.parse(oldModeInfo) : []
  const filteredInfo = parsedOldModeInfo.filter((user) => user.timestamp + 10 * 60000 > new Date().getTime())
  const newInfo: TViewModeInfo[] = [
    {
      user_id: idUser,
      token: access_token,
      timestamp: new Date().getTime(),
      recommendMode,
    },
    ...filteredInfo,
  ]
  localStorage.setItem(VIEW_MOD_INFO, JSON.stringify(newInfo))
}

export const onViewLikeAUser = (idUser: number, dispatch: any) => {
  dispatch(
    GetTokensForViewLikeAUser.request({
      idUser,
      with_terms_confirmed: false,
      callBack: (success, { access_token }) => {
        if (success) {
          const parsedToken = getParsedAccessToken(access_token)
          let href
          if (parsedToken?.permissions?.id_of_admin_role) href = `${window.location.origin}${ROUTES.adminPageTender}`
          else href = `${window.location.origin}${ROUTES.dashboard}`

          setViewLikeInfo(idUser, access_token, false)
          const a = document.createElement('a')
          a.target = '_blank'
          a.rel = 'noopener noreferrer'
          a.href = `${href}?view_user_id=${idUser}`
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      },
    })
  )
}

export const onPersonalRecMod = (idUser: number, dispatch: any, url: string) => {
  dispatch(
    GetTokensForViewLikeAUser.request({
      idUser,
      with_terms_confirmed: true,
      callBack: (success, { access_token }) => {
        if (success) {
          const href = `${SECOND_DOMAIN_URL || window.location.origin}/${url}`
          setViewLikeInfo(idUser, access_token, true)

          const a = document.createElement('a')
          a.target = '_blank'
          a.rel = 'noopener noreferrer'
          a.href = `${href}?view_user_id=${idUser}`
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      },
    })
  )
}

export const openLinkInNewTab = (link: string) => {
  const a = document.createElement('a')
  a.target = '_blank'
  a.rel = 'noopener noreferrer'
  a.href = `${link}`
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export const onDownloadCsvTemplate = (dispatch: any) => {
  dispatch(
    GetUsersCsvTemplate.request({
      callBack: (success, data) => {
        if (success) downloadFile('text/csv', data, 'users.csv')
      },
    })
  )
}
export const onDownloadCategoryCsv = (dispatch: any, categoryId: number) => {
  let fileName = ''
  if (categoryId === 1) fileName = 'diamonds.csv'
  if (categoryId === 2) fileName = 'jewelry.csv'
  if (categoryId === 3) fileName = 'watches.csv'

  dispatch(
    GetCategoryCsv.request({
      categoryId,
      callBack: (success, data) => {
        if (success) downloadFile('text/csv', data, fileName)
      },
    })
  )
}

export const onAddUser = (history: any) =>
  history.push({
    pathname: `${ROUTES.adminPageUsersCustomers}/create-user`,
    state: { fromUsers: true },
  })

export const onAddCompany = (history: any) =>
  history.push({
    pathname: `${ROUTES.adminPageUsersCompanies}/create-company`,
    state: { fromUsers: true },
  })

export const onEditUser = (userId: number, history: any) =>
  history.push({
    pathname: `${ROUTES.adminPageUsersCustomers}/edit-user/${userId}`,
    state: { fromUsers: true },
  })

export const onCreateMessage = (users: number[], dispatch: any, history: any) => {
  dispatch(SetCurrentUsersId(users))
  history.push({
    pathname: `${ROUTES.adminPageUsersCustomers}/create-message/email`,
    state: { search: window.location.search },
  })
}

export const onEditCompany = (companyId: number, history: any) =>
  history.push({
    pathname: `${ROUTES.adminPageUsersCompanies}/edit-company/${companyId}`,
    state: { fromCompanies: true },
  })
