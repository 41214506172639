export const API_PATH = process.env.REACT_APP_API_PATH || ''
export const TOKEN = {
  access: process.env.REACT_APP_ACCESS_TOKEN || 'X-Access-Token',
  refresh: process.env.REACT_APP_REFRESH_TOKEN || 'X-Refresh-Token',
}
export const RECOMMEND_MOD = 'RCMMND_MD'
export const SCROLL = 'SCRLY'
export const SCROLL_GALLERY = 'GSCRLY'
export const ENVIRONMENT =
  process.env.NODE_ENV === 'development'
    ? 'local'
    : (process.env.REACT_APP_ENV as 'local' | 'test' | 'development' | 'stage' | 'production' | undefined)

export const CATALOG_PUBLISHED_DATE_LENGTH = 23
export const TENDER_START_TIME_LENGTH = 38
export const TENDER_CLOSING_TIME_LENGTH = 38
export const TENDER_LINK_LENGTH = window.location.host.length + 14
export const RESULT_PUBLISHED_LENGTH = 23
export const WORTHY_DOMAIN_URL = process.env.REACT_APP_WORTHY_DOMAIN_URL || ''
export const SECOND_DOMAIN_URL = process.env.REACT_APP_SECOND_DOMAIN || ''
export const WORTHY_REDIRECT = 'worthy-redirect'

export const fileStackSource = ['local_file_system', 'googledrive', 'dropbox']

export const filestackBacket = () => {
  switch (ENVIRONMENT) {
    case 'stage':
      return 'circatender-stage'

    case 'production':
      return 'circatender'

    default:
      return 'circatender-dev'
  }
}

export const viewAs: boolean = !!sessionStorage.getItem(TOKEN.access)
export const recMode: boolean = viewAs && !!sessionStorage.getItem(RECOMMEND_MOD)
export const VIEW_MOD_INFO = 'V_M_I'
