/* eslint-disable react/require-default-props */
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { TGalleryItemInfo } from '../../../store/gallery/types'
import { Close } from '../../../svg/Close'
import { useAddItemPopup } from '../../../hooks/useAddItemPopup'
import { PurchasePopup } from './PurchasePopup'
import { ViewAsCustomer } from '../../ViewAsCustomer'
import { AppStore } from '../../../store/applicationState'
import { DiamondPopupBody } from './itemPopupUi/DiamondPopupBody'
import { WatchesPopupBody } from './itemPopupUi/WatchesPopupBody'
import { JewelryPopupBody } from './itemPopupUi/JewelryPopupBody'

export interface ItemPopupProps {
  item: TGalleryItemInfo
  isCatalog: boolean
  finalized: boolean
  bidFromTable?: number
}

export const ItemPopup: React.FC<ItemPopupProps> = ({ item, isCatalog, finalized, bidFromTable }) => {
  const [purchasePopupActive, setPurchasePopupActive] = useState(false)
  const [isTenderCatalog, setIsTenderCatalog] = useState(isCatalog)
  const { remove } = useAddItemPopup()
  const tenderId = Number(window.location.pathname.split('/').find((elem) => Number(elem)))
  const { gallery, currentTime } = useSelector((store: AppStore) => store)

  useEffect(() => {
    if (gallery.data.tenderInfo && gallery.data.showPrices && gallery.data.pricesAreLoaded) {
      let timeDiff = 0
      if (gallery.data.showPrices === 0) {
        const serverTime = Math.round(Number(currentTime.data) / 1000000)
        timeDiff = serverTime ? new Date().getTime() - serverTime : 0
      }
      const startAt = new Date(gallery.data.tenderInfo.tender_info.start_at)

      if (currentTime && gallery.data.tenderItems) {
        setIsTenderCatalog(startAt.getTime() > new Date().getTime() - timeDiff)
      }
    }
  }, [gallery.data.tenderInfo, gallery.data.showPrices, gallery.data.pricesAreLoaded])

  // useEffect(() => {
  //   if (currentTime && gallery.data.tenderInfo && isCatalog) {
  //     const serverTime = Math.round(Number(currentTime.data) / 1000000)
  //     const timeDiff = serverTime ? new Date().getTime() - serverTime : 0
  //     const startAt = new Date(gallery.data.tenderInfo.tender_info.start_at)
  //     setIsTenderCatalog(startAt.getTime() > new Date().getTime() - timeDiff)
  //   }
  // }, [gallery.data.tenderInfo, currentTime.data])

  const popupItem2 = useMemo(() => {
    if (gallery.data.showPrices && gallery.data.pricesAreLoaded) {
      if (!item.product.asking_price && !item.product.rap_list_price_discount && !item.product.asking_price_per_carat) {
        const itemFromGallery = gallery.data.tenderItems?.find((tItem) => tItem.item_id === item.item_id)
        if (itemFromGallery) return itemFromGallery
      }
    }
    return item
  }, [gallery.data.showPrices, gallery.data.pricesAreLoaded, item])

  const itemBody = () => {
    switch (item.category_id) {
      case 1:
        return (
          <DiamondPopupBody
            item={popupItem2}
            isCatalog={isTenderCatalog}
            finalized={finalized || false}
            bidFromTable={bidFromTable}
            setPurchasePopupActive={setPurchasePopupActive}
          />
        )

      case 2:
        return (
          <JewelryPopupBody
            item={popupItem2}
            isCatalog={isTenderCatalog}
            finalized={finalized || false}
            setPurchasePopupActive={setPurchasePopupActive}
          />
        )

      case 3:
        return (
          <WatchesPopupBody
            item={popupItem2}
            isCatalog={isTenderCatalog}
            finalized={finalized || false}
            setPurchasePopupActive={setPurchasePopupActive}
          />
        )

      default:
        return ''
    }
  }

  return (
    <div
      id='itemPopupComponent'
      className='item-popup fixed inset-0 bg-bluegray-600 w-screen h-full overflow-y-auto overscroll-none py-4 px-8 md:px-4 z-[180]'
      onTouchMove={() => {
        const xzoomsource = document.getElementsByClassName('xzoom-source')[0]
        if (xzoomsource) xzoomsource.remove()
      }}
    >
      <ViewAsCustomer className='sticky -top-4 -mx-8 md:-mx-4 z-[10000]' />
      <button
        type='button'
        onClick={() => remove()}
        className='float-right sticky top-1 text-bluegray-300 cursor-pointer text-2xl p-2 -mr-8 md:-mr-4 lg:-mr-0'
      >
        <Close />
      </button>

      <div className='md:w-11/12 w-12/12 2xl:w-12/12 mx-auto pt-2 space-y-4 md:space-y-10 md:max-w-5xl 2.5xl:max-w-7xl 3xl:max-w-[86rem]'>
        {itemBody()}
      </div>

      {purchasePopupActive && (
        <PurchasePopup
          setPurchasePopupActive={setPurchasePopupActive}
          purchasePopupActive={purchasePopupActive}
          item={popupItem2}
          tenderId={tenderId}
        />
      )}
    </div>
  )
}
