import { useEffect } from 'react'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { checkToken, ReturnType } from '../utils/checkToken'
import { ROUTES } from '../utils/routes'
import { listenLogin, setStore } from '../utils/functions'
import { ENVIRONMENT, RECOMMEND_MOD, TOKEN, VIEW_MOD_INFO } from '../utils/consts'
import { AppStore } from '../store/applicationState'
import { useLoginFromWorthy } from './useLoginFromWorthy'
import { TViewModeInfo } from '../types/viewLikeTypes'

export const useLoadApp = () => {
  const history = useHistory()
  const location = useLocation()
  const { checkRedirectToWorthy } = useLoginFromWorthy()

  const dispatch = useDispatch()
  const { signIn } = useSelector((store: AppStore) => store)

  const { view_user_id } = queryString.parse(location.search)

  useEffect(() => {
    if (signIn.authorized) {
      ;(async () => {
        const userToken: ReturnType = await checkToken()
        if (userToken.success) {
          checkRedirectToWorthy()
          setStore(userToken, dispatch)
          if (location.pathname.startsWith(ROUTES.successReset)) history.replace(ROUTES.dashboard)
        }
      })()
    }
    window.addEventListener('storage', listenLogin)
    return () => {
      window.removeEventListener('storage', listenLogin)
    }
  }, [signIn.authorized])

  useEffect(() => {
    const init = () => {
      let reload = false
      if (typeof view_user_id === 'string') {
        const oldModeInfo = localStorage.getItem(VIEW_MOD_INFO)
        const parsedOldModeInfo: TViewModeInfo[] = oldModeInfo ? JSON.parse(oldModeInfo) : []
        const viewUser = parsedOldModeInfo.find((user) => user.user_id === +view_user_id)

        if (viewUser) {
          if (!sessionStorage.getItem(TOKEN.access)) {
            sessionStorage.setItem(TOKEN.access, viewUser.token)
            reload = true
          }
          if (viewUser.recommendMode && !sessionStorage.getItem(RECOMMEND_MOD)) {
            sessionStorage.setItem(RECOMMEND_MOD, `${viewUser.recommendMode}`)
            reload = true
          }
          if (parsedOldModeInfo && parsedOldModeInfo.length > 1) {
            const filteredInfo = parsedOldModeInfo.filter((user) => user.user_id !== +view_user_id)
            localStorage.setItem(VIEW_MOD_INFO, JSON.stringify(filteredInfo))
          }
        }
      }
      if (reload) window.location.reload()
    }

    init()

    if (ENVIRONMENT === 'local') document.title = `[LOC] | ${document.title}`
    else if (ENVIRONMENT === 'development') document.title = `[DEV] | ${document.title}`
    else if (ENVIRONMENT === 'test') document.title = `[TEST] | ${document.title}`
    else if (ENVIRONMENT === 'stage') document.title = `[STAGE] | ${document.title}`
  }, [])
}
